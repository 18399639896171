@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'DM Sans';
  color: #181717;
  background: #F3F3F3;
  padding: 30px;
}
.typing-cursor {
  display: inline-block;
  width: 4px;
  height: 0.7em;
  background-color: black;
  margin-left: 5px;
  animation: blink 0.7s step-end infinite;
}

@keyframes blink {
  50% {
    background-color: transparent;
  }
}


@media (min-width: 640px) {
  body {
      padding-left: 80px;
      padding-right: 80px;
  }
}

@media (min-width: 768px) {
  body {
      padding-left: 120px;
      padding-right: 120px;
  }
}


